<template>
  <div>
    <button
      class="btn btn-info btn-sm"
      data-toggle="tooltip"
      data-placement="top"
      title="Note"
      @click="fnShowModal()"
    >
      New
    </button>

    <b-modal ref="previousAddressModal" size="lg" hide-footer title="Note">
      <div class="d-block">
        <div class="form-group">
          <label for="post_code">Subject</label>
          <input
            type="text"
            class="form-control"
            placeholder="Subject"
            v-model="subject"
          />
        </div>
        <div>
          <vue-editor v-model="content" />
        </div>
        <div class="text-right mt-3">
          <button
            type="button"
            class="btn btn-secondary btn-sm mr-2"
            @click="hideModal"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-info btn-sm"
            @click="createNote()"
          >
            <b-spinner small v-if="isLoadingSave" class="mr-2"></b-spinner>Save
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import * as messageService from "../../services/message.service";
import * as notesService from "../../services/notes.service";
import * as notesPublicService from "../../services/notesPublic.service";
import moment from "moment";
export default {
  name: "ModalNoteNew",
  props: ["noteType", "checkId", "productId"],
  components: {},
  data() {
    return {
      subject: "",
      content: "",
      errors: {},
      isLoadingSave: false,
    };
  },
  methods: {
    fnShowModal() {
      this.showModal();
    },
    hideModal() {
      this.$refs.previousAddressModal.hide();
    },
    showModal() {
      this.$refs.previousAddressModal.show();
    },
    createNote: async function () {
      this.isLoadingSave = true;
      const objData = {
        content: {
          subject: this.subject,
          note: this.content,
          date: moment().format("YYYY-MM-DD hh:mm:ss A"),
        },
      };

      try {
        if (this.noteType === "private") {
          await notesService.add(
            this.checkId,
            this.productId,
            objData
          );
          this.$emit('reloadPrivateData')

        } else if (this.noteType === "public") {
          await notesPublicService.add(
            this.checkId,
            this.productId,
            objData
          );

          this.$emit('reloadPublicData')
        }
        this.hideModal();

        messageService.fnToastSuccess("Note - Successfully added");

        this.content = "";
        this.isLoadingSave = false;
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Notes / Messages",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Notes / Messages",
              "Something wrong"
            );
            break;
        }
        this.isLoadingSave = false;
      }
    },
  },
};
</script>
