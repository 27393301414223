<template>
  <div>
    <button
      class="btn btn-info btn-sm"
      data-toggle="tooltip"
      data-placement="top"
      title="Note Edit"
      @click="fnShowModal()"
    >
      Edit
    </button>

    <b-modal
      ref="previousAddressModal"
      size="lg"
      hide-footer
      title="Note - Edit"
    >
      <div class="d-block">
        <div class="form-group">
          <label for="post_code">Subject </label>
          <input
            type="text"
            class="form-control"
            placeholder="Subject"
            v-model="formData.subject"
          />
        </div>

        <div>
          <vue-editor v-model="formData.note" />
        </div>
        <div class="text-right mt-3">
          <button
            type="button"
            class="btn btn-secondary btn-sm mr-2"
            @click="hideModal"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-info btn-sm"
            @click="updateNote()"
          >
            <b-spinner small v-if="isLoadingSave" class="mr-2"></b-spinner
            >Update
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import * as messageService from "../../services/message.service";
import * as notesService from "../../services/notes.service";
import * as notesPublicService from "../../services/notesPublic.service";
import moment from "moment";
export default {
  name: "ModalNoteEdit",
  props: [
    "noteType",
    "noteId",
    "propsNoteData",
    "propsIndex",
  ],
  components: {},
  data() {
    return {
      formData: {
        subject: "",
        note: "",
      },
      errors: {},
      isLoadingSave: false,
    };
  },
  methods: {
    ...mapActions(["fetchNotesList", "fetchNotesPublicList"]),
    fnShowModal() {
      this.showModal();
    },
    hideModal() {
      this.$refs.previousAddressModal.hide();
    },
    showModal() {
      this.formData = this.propsNoteData;
      this.$refs.previousAddressModal.show();
    },
    updateNote: async function () {
      this.isLoadingSave = true;
      const objData = {
        content: {
          subject: this.formData.subject,
          note: this.formData.note,
          date: moment().format("YYYY-MM-DD hh:mm:ss A"),
        },
      };

      try {
        if (this.noteType == "private") {
          await notesService.update(
            this.noteId,
            this.propsIndex,
            objData
          );

          this.$emit('reloadPrivateData')

        } else if (this.noteType == "public") {
          await notesPublicService.update(
            this.noteId,
            this.propsIndex,
            objData
          );

          this.$emit('reloadPublicData')
        }

        this.hideModal();

        messageService.fnToastSuccess("Note - Successfully updated");

        this.content = "";
        this.isLoadingSave = false;
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Notes/Message",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Notes/Message",
              "Something wrong"
            );
            break;
        }
        this.isLoadingSave = false;
      }
    },
  },
};
</script>
