<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-8">
          <h5 class="text-info mb-0">Notes <span class="fs-800">(for CVS)</span></h5>
        </div>

        <div class="col-4 text-right">
          <div v-if="cvs_all_can_access() == true">
            <appModalNoteNew
              :noteType="noteType"
              :checkId="checkId"
              :productId="productId"
              @reloadPrivateData="fnReloadNotes"
            >
            </appModalNoteNew>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div
        class="accordion"
        role="tablist"
        v-if="
        getNotesList &&
        getNotesList.content &&
        getNotesList.content.length > 0
        "
      >
        <div v-for="(item, index) in getNotesList.content" :key="index">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button
                block
                href="#"
                v-b-toggle="'accordion-note-' + index"
                variant="secondary"
                class="text-left"
                >{{ item.date }} | {{ item.subject }}</b-button
              >
            </b-card-header>
            <b-collapse
              :id="'accordion-note-' + index"
              accordion="my-accordion-note"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text v-html="item.note"></b-card-text>
                <div class="text-right" v-if="cvs_all_can_access() == true">
                  <appModalNoteEdit
                    :noteType="noteType"
                    :noteId="getNotesList.id"
                    :propsNoteData="item"
                    :propsIndex="index"
                  ></appModalNoteEdit>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
      <div v-else>
        <p>There are no records</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters,mapActions } from "vuex";
import * as authService from "../../services/auth.service";
import appModalNoteNew from "./ModalNoteNew.vue";
import appModalNoteEdit from "./ModalNoteEdit.vue";
export default {
  name: "CheckListNotesCom",
  props: [
    "noteType",
    "checkId",
    "productId",
  ],
  components: {
    appModalNoteNew,
    appModalNoteEdit,
  },
  data() {
    return {
      loginUserObj: {},
    };
  },
  computed: {
    ...mapGetters([
      "getNotesList",
      "getPoliceCheckSingle",
      "getRightWorkAustralia",
      "getReference",
      "getAcademicQualification",
      "getProfessionalMembership",
      "getEmploymentHistory",
      "getBankruptcyRecord",
      "getDriversOffences"
    ]),
  },
  methods: {
    ...mapActions([
      "fetchNotesList",
      "fetchPoliceCheckSingle",
      "fetchRightWorkAustraliaById",
      "fetchReferenceById",
      "fetchAcademicQualificationById",
      "fetchProfessionalMembershipById",
      "fetchEmploymentHistoryById",
      "fetchBankruptcyRecordById",
      "fetchDriversOffencesById",
    ]),
    cvs_all_can_access() {
      if (this.loginUserObj.role_id == 1 || this.loginUserObj.role_id == 2) {
        return true;
      }
      return false;
    },
    async fnReloadNotes(){
      // Police check
      if (this.productId == 1 || this.productId == 2) {
        await this.fetchPoliceCheckSingle(this.checkId);
        await this.fetchNotesList(this.getPoliceCheckSingle.noteId);
      }

      if (this.productId == 3) {
        await this.fetchRightWorkAustraliaById(this.checkId);
        await this.fetchNotesList(this.getRightWorkAustralia.noteId);
      }

      if (this.productId == 4 || this.productId == 10) {
        await this.fetchReferenceById(this.checkId);
        await this.fetchNotesList(this.getReference.noteId);
      }

      // Academic qualification single by Id
      if (this.productId == 5) {
        await this.fetchAcademicQualificationById(this.checkId);
        await this.fetchNotesList(this.getAcademicQualification.noteId);
      }

      if (this.productId == 6) {
        await this.fetchProfessionalMembershipById(this.checkId);
        await this.fetchNotesList(this.getProfessionalMembership.noteId);
      }

      if (this.productId == 7) {
        await this.fetchEmploymentHistoryById(this.checkId);
        await this.fetchNotesList(this.getEmploymentHistory.noteId);
      }

      if (this.productId == 8) {
        await this.fetchBankruptcyRecordById(this.checkId);
        await this.fetchNotesList(this.getBankruptcyRecord.noteId);
      }

      if (this.productId == 9) {
        await this.fetchDriversOffencesById(this.checkId);
        await this.fetchNotesList(this.getDriversOffences.noteId);
      }
    }
  },
  async created() {
    this.loginUserObj = authService.getUserFromToken();

    await this.fnReloadNotes();
  },
  watch:{
    async checkId(val){
      await this.fnReloadNotes();
    },
  }
};
</script>
